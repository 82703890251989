<template>
  <div>
    <div :class="getStatusClass()">
      <div class="badge-text-sm">
        {{ getStatus() }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: Object,
      default: this
    }
  },
  methods: {
    getStatus() {
      if (this.status.salesStatus.canceled === true) {
        return this.$t('views.shop.sales-badge.canceled');
      }
      if (this.status.salesStatus.canceled === false && this.status.salesStatus.cart.restToPay === 0) {
        return this.$t('views.shop.sales-badge.paid');
      }
      if(this.status.salesStatus.canceled === false && this.status.salesStatus.cart.restToPay > 0)
      return this.$t('views.shop.sales-badge.not-paid');
    },
    getStatusClass() {
      if (this.status.salesStatus.canceled === true) {
        return 'badge-available-error';
      }
      if (this.status.salesStatus.canceled === false && this.status.salesStatus.cart.restToPay === 0) {
        return 'badge-available-success';
      }
      if (this.status.salesStatus.canceled === false && this.status.salesStatus.cart.restToPay > 0) {
        return 'badge-available-pending';
      }
    }
  },
}
</script>